import { EXTENSION_BROADCAST_CHANNEL, WINDOW_MESSAGE_EVENT_TYPES } from '@/util/constants';
import * as Sentry from '@sentry/nuxt';

interface BroadcastMessage {
  type: string;
  [key: string]: unknown;
}

export default defineNuxtPlugin(() => {
  const {
    data,
    post,
  } = useBroadcastChannel({ name: EXTENSION_BROADCAST_CHANNEL });

  const userStore = useUserStore();
  const { handleLogout } = useAuth();

  function handleIncomingMessage(message: unknown) {
    const messageData = message as BroadcastMessage;
    const messageType = messageData?.type;

    switch (messageType) {
      // Received a login event from another window/extension
      case WINDOW_MESSAGE_EVENT_TYPES.LOGIN:
        userStore.fetchLoggedInUser();
        break;
      // Received a logout event from another window/extension
      case WINDOW_MESSAGE_EVENT_TYPES.LOGOUT:
      case WINDOW_MESSAGE_EVENT_TYPES.EXT_LOGOUT:
        handleLogout();
        break;
    }
  }

  function postMessage(message: unknown) {
    try {
      // Post to broadcast channel
      post(message);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  // Listen for new data received on this channel
  watch(data, () => handleIncomingMessage(data.value));

  return {
    provide: {
      broadcast: {
        post: postMessage,
      },
    },
  };
});
