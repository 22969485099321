import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores';
import { parseUserAgent } from '@/util/userAgent';
import { GA_EVENT } from '@/util/analytics';

const APP_ID = 'sc-web';
const APP_VERSION = '2.0';

export default function useAnalytics() {
  /**
   * Builds analytics data by combining default data with any extra data provided.
   *
   * @param {Record<string, string | number | string[] | number[]>} extraData - Additional data to include in the analytics.
   * @return {Record<string, string | number | string[] | number[]>} The combined analytics data.
   */
  const buildAnalyticsData = (extraData?: Record<string, string | number | string[] | number[]>) => {
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);

    const fbcCookie = useCookie('_fbc');
    const fbpCookie = useCookie('_fbp');

    const { isLoggedIn } = useUser();
    const userId = computed(() => user?.value?.profile.id);
    const guestUser = computed(() => user?.value && user.value?.isGuest === true);

    const defaultData: Record<string, any> = {
      // App details
      appName: APP_ID,
      appId: APP_ID,
      appVersion: APP_VERSION,

      // User details
      userId: userId.value,
      logged_in: isLoggedIn.value,
      guest_user: guestUser.value,
      sc_user_id: userId.value, // XXX: Redundant with userId param, consider removing

      // Facebook/Meta marketing
      fbc: fbcCookie.value,
      fbp: fbpCookie.value,
    };

    // Marketing expects "merchantId" instead of "merchant_id"
    if (extraData?.merchant_id)
      defaultData.merchantId = extraData.merchant_id;

    return {
      ...defaultData,
      ...extraData,
    };
  };

  /**
   * Sends a Google Analytics event with the given action and extra options.
   *
   * @param {string} action - The action to be tracked in Google Analytics.
   * @param {Record<string, any>} extraOptions - Additional options to be included in the event.
   * @return {void} This function does not return anything.
   */
  const sendGAEvent = (action: string, extraOptions: Record<string, any> = {}) => {
    if (import.meta.client) {
      const analyticsData = buildAnalyticsData(extraOptions);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: action,
        ...analyticsData,
        url: window.location.href,
      });
    }
  };

  /**
   * A function to send a Google Analytics page view.
   *
   * @return {void} This function does not return anything.
   */
  const sendGAPageView = () => {
    if (import.meta.client) {
      const analyticsData = buildAnalyticsData({});

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: GA_EVENT.PAGE_VIEW,
        ...analyticsData,
        page_location: window.location.href,
        page_title: document.title,
      });
    }
  };

  /**
   * Generates the extension platform name based on the user agent and device type.
   *
   * @return {string} The extension platform name.
   */
  const buildExtPlatformName = () => {
    const ua = parseUserAgent();
    const { isIos } = useDevice();

    let extType = '';
    const chromeName = ua.browser?.name || '';

    switch (chromeName) {
      case 'chrome':
      case 'firefox':
      case 'edge':
        extType = `${chromeName}_ext`;
        break;
      case 'safari':
        extType = isIos ? 'ios_safari_ext' : 'osx_safari_ext';
        break;
      default:
        extType = '';
    }

    return extType;
  };

  return { buildAnalyticsData, sendGAEvent, sendGAPageView, buildExtPlatformName };
}
