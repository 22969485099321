
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutoaxnW4uskm87NhX75ZswBtuYUDM2pqxt70C8wqrcLn4Meta } from "/usr/src/app/src/pages/about.vue?macro=true";
import { default as best_45coupon_45app_45installnowvyMqXVJyYH_U9JZ4ozOFWo2CL5T1d5aD2TDXaJFKADgMeta } from "/usr/src/app/src/pages/best-coupon-app-installnow.vue?macro=true";
import { default as _91slug_93oSz0JajzeDtFtqXBN92pKjKCOQbJVnIviZQ4pZkCjYwMeta } from "/usr/src/app/src/pages/blog/[slug].vue?macro=true";
import { default as indexGGY8W7_jhONuxwYrDwY_45wwM2lnyVJYMtCU5rr1TN3pMMeta } from "/usr/src/app/src/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46category_9368jl552tuBonDTPZWVlXfTOeyF_45GWg7_dxAFGYfc8f0Meta } from "/usr/src/app/src/pages/category/[...category].vue?macro=true";
import { default as indexyZVgLvGG47KIZuDBJxMyVUXKDj7szzBAYx4fvZqWSZQMeta } from "/usr/src/app/src/pages/category/index.vue?macro=true";
import { default as communitiesQU2zg48rZ7uZ3YA0ozA1zKT8DKJJWVJJCBhEz5W8rFcMeta } from "/usr/src/app/src/pages/communities.vue?macro=true";
import { default as earnqTxohFXhaZBHeA_DYdePDB1JaCmeSKYuCyLuF_45XbWYMMeta } from "/usr/src/app/src/pages/earn.vue?macro=true";
import { default as exploreNSAgSExDk2m0_45vbYBMVewk8CtdIfBnmWYPnyJq51G7YMeta } from "/usr/src/app/src/pages/explore.vue?macro=true";
import { default as _91method_93rdwJ2kAFMKQfXH_45GJug4GHv5Vj_458PX0otIYx9EkCnYIMeta } from "/usr/src/app/src/pages/extension/login/[method].vue?macro=true";
import { default as feedbackueJjKNWRpRPRs1ZKulzdSDltH_45Z40ietJ7D4gpZIX40Meta } from "/usr/src/app/src/pages/feedback.vue?macro=true";
import { default as indexuBsWoMeQ_d8w8D_L0Mwo6zTf3WU5Dl_45g89Px0TrLqQQMeta } from "/usr/src/app/src/pages/index.vue?macro=true";
import { default as _91browser_93rinxBZbF20CHOurDO6D1Fa0qkgWfR16e2JbiBxtqmCMMeta } from "/usr/src/app/src/pages/installed/[browser].vue?macro=true";
import { default as indexBgk8eQ_45UAFfEM_LzP5aLlx7Zl66fKeCFERSs0CViUe8Meta } from "/usr/src/app/src/pages/installed/safari-ios/index.vue?macro=true";
import { default as permissions_45workaroundlb0h6OCWcQMoU7Xw2n658CMlcnaMXENp9USZiyFi5msMeta } from "/usr/src/app/src/pages/installed/safari-ios/permissions-workaround.vue?macro=true";
import { default as permissionsD_45B05WWr71jQwnsS9ftcg42X2FOnuFZ2sICnrHimNy4Meta } from "/usr/src/app/src/pages/installed/safari-ios/permissions.vue?macro=true";
import { default as _91code_93HtmTspAnLIeO6_45Jy4mNiq21Ree2dQYLfYyKcVX7d6UEMeta } from "/usr/src/app/src/pages/invite/[code].vue?macro=true";
import { default as indexbFIeImzJ_45Q56Wf83XZxyfdnnQ3rT7o5BigJIeSI09mEMeta } from "/usr/src/app/src/pages/invite/index.vue?macro=true";
import { default as jetsetterxL2VmRZs9GYEkWr9sVdbNtJWy6nPMmpss7P990UqkzgMeta } from "/usr/src/app/src/pages/jetsetter.vue?macro=true";
import { default as loginG1ModxHzsvShKQFJLO27FQl_E5QrBSpaokXhcGWF6YQMeta } from "/usr/src/app/src/pages/login.vue?macro=true";
import { default as missing_45karmaqsHtEOCWaM_WY8v0QwnhpriCx_UYhKrKKjRhsULvbuoMeta } from "/usr/src/app/src/pages/missing-karma.vue?macro=true";
import { default as edit_45profilegqAWXsJipR2XT9D2C_45vfA_45ORetqS2q0LlxEt77mPoT8Meta } from "/usr/src/app/src/pages/my-account/edit-profile.vue?macro=true";
import { default as cashed_45outX8jUmh_45_45zmwCxJLjb_eFxSJ7qWLIaDShqCJNzIqlDbEMeta } from "/usr/src/app/src/pages/my-account/history/cashed-out.vue?macro=true";
import { default as earnedgxfwvH_45Hg9kjPuZ7mXfW6u8ZVZg7jDyXndjIWAx2oBAMeta } from "/usr/src/app/src/pages/my-account/history/earned.vue?macro=true";
import { default as indexkphLEkLbTlRemEQ9mFNxsNZ6xD9_WSw6cK6JzuKFgagMeta } from "/usr/src/app/src/pages/my-account/history/index.vue?macro=true";
import { default as redeemedXGWhmKWlAoAgmuq45l_oRfpTB5RdZYxC8MdZiBl_V9wMeta } from "/usr/src/app/src/pages/my-account/history/redeemed.vue?macro=true";
import { default as historySdPIFQAK1ct6Xd03E_45CzzALQDS6_45Qu5mnvSDq6j5v6IMeta } from "/usr/src/app/src/pages/my-account/history.vue?macro=true";
import { default as indexfQEYU96EIzKqOWrz2m8b1g2Hu3WlHWz1v_p7Z7wxtKwMeta } from "/usr/src/app/src/pages/my-account/index.vue?macro=true";
import { default as redeem_45cashDlIDdfAYCCORsDxG4C9_457M1POm5uAqTdH6AqpiT05CYMeta } from "/usr/src/app/src/pages/my-account/redeem-cash.vue?macro=true";
import { default as account_45info7drK6s7Wloq0s8wb1l0xrtvrBnbJi5z6xtDlfATWybkMeta } from "/usr/src/app/src/pages/my-account/settings/account-info.vue?macro=true";
import { default as indexFoLaiGon0wEA2QtuHQNMy4tb32_S1ksFpK25pQNlXf4Meta } from "/usr/src/app/src/pages/my-account/settings/index.vue?macro=true";
import { default as login_45securitynjrjyhSC_45WzjTKbRxZnSBYLsWnM1ngc_aLlvvR3PWcwMeta } from "/usr/src/app/src/pages/my-account/settings/login-security.vue?macro=true";
import { default as notifications5DH1EBV27_KpuFsb8G_45qqYpjQL_UmdDwWkqXSo3MK0AMeta } from "/usr/src/app/src/pages/my-account/settings/notifications.vue?macro=true";
import { default as personal_45infoBXTVilQYN66ovbMhy65kB7imEbVN37LzJonQZJjj948Meta } from "/usr/src/app/src/pages/my-account/settings/personal-info.vue?macro=true";
import { default as privacykTLltJ1BbVfGdQxdRcqy6hibVGw3nVzXhQi0rzAE8owMeta } from "/usr/src/app/src/pages/my-account/settings/privacy.vue?macro=true";
import { default as my_45accounte_45xcoj1jNzyEzur4mxk5olEI_45Q1_45_459ghLOTL_454Og3lgMeta } from "/usr/src/app/src/pages/my-account.vue?macro=true";
import { default as notify_45consentR5h_45NNH6jJHW2iRLPz9RpS_4UxKLIsdEHfvWoKxw_45gkMeta } from "/usr/src/app/src/pages/notify-consent.vue?macro=true";
import { default as playZcs22BVgqGEdsBwcPVu4yVk2JyPj13q0fpqDdWtn8dMMeta } from "/usr/src/app/src/pages/play.vue?macro=true";
import { default as indexvEKjArzh5roMwFW17Rdxq0s3fLGAiChpVE5gYyqD_45dIMeta } from "/usr/src/app/src/pages/privacy/index.vue?macro=true";
import { default as privacy_45policyNJjQ8_fzfxkPT_455Edg4zkNyOBfjY4TGyMcq4JPuGlUcMeta } from "/usr/src/app/src/pages/privacy/privacy-policy.vue?macro=true";
import { default as _91slug_93hB5uh2gHwaRy2EkLDBvpurnI08mwUeCMamauJ_6ZkH8Meta } from "/usr/src/app/src/pages/shop/[slug].vue?macro=true";
import { default as simplycodes_45mobile_45extensionfauEc0qMpXBLCe1R_45tGy_45_45xhNWA9kBLTXObeJEjEXRcMeta } from "/usr/src/app/src/pages/simplycodes-mobile-extension.vue?macro=true";
import { default as smartshopperB2Md0Yz2bQ31mnlKe_bgFXYB5QC6swhwmEILJVGN3nEMeta } from "/usr/src/app/src/pages/smartshopper.vue?macro=true";
import { default as _91merchant_93aWtXm3rnuN3KJjfjp1TEE6VqXXjR6c7HKgHGnMjW8VIMeta } from "/usr/src/app/src/pages/store/[merchant].vue?macro=true";
import { default as _91_91merchant_93_93LBsBme1v1AvMNHFZpwLvEwEz7x8kREIuEXMT5427NhoMeta } from "/usr/src/app/src/pages/submit-coupon/[[merchant]].vue?macro=true";
import { default as termsiN_GI1vp6To6fN2pnys3OGyBMtr2lfxUP9XxFL9rZZYMeta } from "/usr/src/app/src/pages/terms.vue?macro=true";
import { default as uninstallu1eXmyXbFT43C7_gHWO4HyKEmeZNMEcoh4V1N4Pz7ZwMeta } from "/usr/src/app/src/pages/uninstall.vue?macro=true";
import { default as verify_45codezhC35tDNkCQzcd0yRg8AzBf6aasbvnAy2KE70DXn57AMeta } from "/usr/src/app/src/pages/verify-code.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutoaxnW4uskm87NhX75ZswBtuYUDM2pqxt70C8wqrcLn4Meta || {},
    component: () => import("/usr/src/app/src/pages/about.vue")
  },
  {
    name: "best-coupon-app-installnow",
    path: "/best-coupon-app-installnow",
    meta: best_45coupon_45app_45installnowvyMqXVJyYH_U9JZ4ozOFWo2CL5T1d5aD2TDXaJFKADgMeta || {},
    component: () => import("/usr/src/app/src/pages/best-coupon-app-installnow.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93oSz0JajzeDtFtqXBN92pKjKCOQbJVnIviZQ4pZkCjYwMeta || {},
    component: () => import("/usr/src/app/src/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexGGY8W7_jhONuxwYrDwY_45wwM2lnyVJYMtCU5rr1TN3pMMeta || {},
    component: () => import("/usr/src/app/src/pages/blog/index.vue")
  },
  {
    name: "category-category",
    path: "/category/:category(.*)*",
    meta: _91_46_46_46category_9368jl552tuBonDTPZWVlXfTOeyF_45GWg7_dxAFGYfc8f0Meta || {},
    component: () => import("/usr/src/app/src/pages/category/[...category].vue")
  },
  {
    name: "category",
    path: "/category",
    meta: indexyZVgLvGG47KIZuDBJxMyVUXKDj7szzBAYx4fvZqWSZQMeta || {},
    component: () => import("/usr/src/app/src/pages/category/index.vue")
  },
  {
    name: "communities",
    path: "/communities",
    meta: communitiesQU2zg48rZ7uZ3YA0ozA1zKT8DKJJWVJJCBhEz5W8rFcMeta || {},
    component: () => import("/usr/src/app/src/pages/communities.vue")
  },
  {
    name: "earn",
    path: "/earn",
    meta: earnqTxohFXhaZBHeA_DYdePDB1JaCmeSKYuCyLuF_45XbWYMMeta || {},
    component: () => import("/usr/src/app/src/pages/earn.vue")
  },
  {
    name: "explore",
    path: "/explore",
    meta: exploreNSAgSExDk2m0_45vbYBMVewk8CtdIfBnmWYPnyJq51G7YMeta || {},
    component: () => import("/usr/src/app/src/pages/explore.vue")
  },
  {
    name: "extension-login-method",
    path: "/extension/login/:method()",
    meta: _91method_93rdwJ2kAFMKQfXH_45GJug4GHv5Vj_458PX0otIYx9EkCnYIMeta || {},
    component: () => import("/usr/src/app/src/pages/extension/login/[method].vue")
  },
  {
    name: "feedback",
    path: "/feedback",
    meta: feedbackueJjKNWRpRPRs1ZKulzdSDltH_45Z40ietJ7D4gpZIX40Meta || {},
    component: () => import("/usr/src/app/src/pages/feedback.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexuBsWoMeQ_d8w8D_L0Mwo6zTf3WU5Dl_45g89Px0TrLqQQMeta || {},
    component: () => import("/usr/src/app/src/pages/index.vue")
  },
  {
    name: "installed-browser",
    path: "/installed/:browser()",
    meta: _91browser_93rinxBZbF20CHOurDO6D1Fa0qkgWfR16e2JbiBxtqmCMMeta || {},
    component: () => import("/usr/src/app/src/pages/installed/[browser].vue")
  },
  {
    name: "installed-safari-ios",
    path: "/installed/safari-ios",
    meta: indexBgk8eQ_45UAFfEM_LzP5aLlx7Zl66fKeCFERSs0CViUe8Meta || {},
    component: () => import("/usr/src/app/src/pages/installed/safari-ios/index.vue")
  },
  {
    name: "installed-safari-ios-permissions-workaround",
    path: "/installed/safari-ios/permissions-workaround",
    meta: permissions_45workaroundlb0h6OCWcQMoU7Xw2n658CMlcnaMXENp9USZiyFi5msMeta || {},
    component: () => import("/usr/src/app/src/pages/installed/safari-ios/permissions-workaround.vue")
  },
  {
    name: "installed-safari-ios-permissions",
    path: "/installed/safari-ios/permissions",
    meta: permissionsD_45B05WWr71jQwnsS9ftcg42X2FOnuFZ2sICnrHimNy4Meta || {},
    component: () => import("/usr/src/app/src/pages/installed/safari-ios/permissions.vue")
  },
  {
    name: "invite-code",
    path: "/invite/:code()",
    meta: _91code_93HtmTspAnLIeO6_45Jy4mNiq21Ree2dQYLfYyKcVX7d6UEMeta || {},
    component: () => import("/usr/src/app/src/pages/invite/[code].vue")
  },
  {
    name: "invite",
    path: "/invite",
    meta: indexbFIeImzJ_45Q56Wf83XZxyfdnnQ3rT7o5BigJIeSI09mEMeta || {},
    component: () => import("/usr/src/app/src/pages/invite/index.vue")
  },
  {
    name: "jetsetter",
    path: "/jetsetter",
    meta: jetsetterxL2VmRZs9GYEkWr9sVdbNtJWy6nPMmpss7P990UqkzgMeta || {},
    component: () => import("/usr/src/app/src/pages/jetsetter.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginG1ModxHzsvShKQFJLO27FQl_E5QrBSpaokXhcGWF6YQMeta || {},
    component: () => import("/usr/src/app/src/pages/login.vue")
  },
  {
    name: "missing-karma",
    path: "/missing-karma",
    meta: missing_45karmaqsHtEOCWaM_WY8v0QwnhpriCx_UYhKrKKjRhsULvbuoMeta || {},
    component: () => import("/usr/src/app/src/pages/missing-karma.vue")
  },
  {
    name: my_45accounte_45xcoj1jNzyEzur4mxk5olEI_45Q1_45_459ghLOTL_454Og3lgMeta?.name,
    path: "/my-account",
    meta: my_45accounte_45xcoj1jNzyEzur4mxk5olEI_45Q1_45_459ghLOTL_454Og3lgMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account.vue"),
    children: [
  {
    name: "my-account-edit-profile",
    path: "edit-profile",
    meta: edit_45profilegqAWXsJipR2XT9D2C_45vfA_45ORetqS2q0LlxEt77mPoT8Meta || {},
    component: () => import("/usr/src/app/src/pages/my-account/edit-profile.vue")
  },
  {
    name: historySdPIFQAK1ct6Xd03E_45CzzALQDS6_45Qu5mnvSDq6j5v6IMeta?.name,
    path: "history",
    meta: historySdPIFQAK1ct6Xd03E_45CzzALQDS6_45Qu5mnvSDq6j5v6IMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/history.vue"),
    children: [
  {
    name: "my-account-history-cashed-out",
    path: "cashed-out",
    meta: cashed_45outX8jUmh_45_45zmwCxJLjb_eFxSJ7qWLIaDShqCJNzIqlDbEMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/history/cashed-out.vue")
  },
  {
    name: "my-account-history-earned",
    path: "earned",
    meta: earnedgxfwvH_45Hg9kjPuZ7mXfW6u8ZVZg7jDyXndjIWAx2oBAMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/history/earned.vue")
  },
  {
    name: "my-account-history",
    path: "",
    meta: indexkphLEkLbTlRemEQ9mFNxsNZ6xD9_WSw6cK6JzuKFgagMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/history/index.vue")
  },
  {
    name: "my-account-history-redeemed",
    path: "redeemed",
    meta: redeemedXGWhmKWlAoAgmuq45l_oRfpTB5RdZYxC8MdZiBl_V9wMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/history/redeemed.vue")
  }
]
  },
  {
    name: "my-account",
    path: "",
    meta: indexfQEYU96EIzKqOWrz2m8b1g2Hu3WlHWz1v_p7Z7wxtKwMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/index.vue")
  },
  {
    name: "my-account-redeem-cash",
    path: "redeem-cash",
    meta: redeem_45cashDlIDdfAYCCORsDxG4C9_457M1POm5uAqTdH6AqpiT05CYMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/redeem-cash.vue")
  },
  {
    name: "my-account-settings-account-info",
    path: "settings/account-info",
    meta: account_45info7drK6s7Wloq0s8wb1l0xrtvrBnbJi5z6xtDlfATWybkMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/settings/account-info.vue")
  },
  {
    name: "my-account-settings",
    path: "settings",
    meta: indexFoLaiGon0wEA2QtuHQNMy4tb32_S1ksFpK25pQNlXf4Meta || {},
    component: () => import("/usr/src/app/src/pages/my-account/settings/index.vue")
  },
  {
    name: "my-account-settings-login-security",
    path: "settings/login-security",
    meta: login_45securitynjrjyhSC_45WzjTKbRxZnSBYLsWnM1ngc_aLlvvR3PWcwMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/settings/login-security.vue")
  },
  {
    name: "my-account-settings-notifications",
    path: "settings/notifications",
    meta: notifications5DH1EBV27_KpuFsb8G_45qqYpjQL_UmdDwWkqXSo3MK0AMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/settings/notifications.vue")
  },
  {
    name: "my-account-settings-personal-info",
    path: "settings/personal-info",
    component: () => import("/usr/src/app/src/pages/my-account/settings/personal-info.vue")
  },
  {
    name: "my-account-settings-privacy",
    path: "settings/privacy",
    meta: privacykTLltJ1BbVfGdQxdRcqy6hibVGw3nVzXhQi0rzAE8owMeta || {},
    component: () => import("/usr/src/app/src/pages/my-account/settings/privacy.vue")
  }
]
  },
  {
    name: "notify-consent",
    path: "/notify-consent",
    meta: notify_45consentR5h_45NNH6jJHW2iRLPz9RpS_4UxKLIsdEHfvWoKxw_45gkMeta || {},
    component: () => import("/usr/src/app/src/pages/notify-consent.vue")
  },
  {
    name: "play",
    path: "/play",
    meta: playZcs22BVgqGEdsBwcPVu4yVk2JyPj13q0fpqDdWtn8dMMeta || {},
    component: () => import("/usr/src/app/src/pages/play.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: indexvEKjArzh5roMwFW17Rdxq0s3fLGAiChpVE5gYyqD_45dIMeta || {},
    component: () => import("/usr/src/app/src/pages/privacy/index.vue")
  },
  {
    name: "privacy-privacy-policy",
    path: "/privacy/privacy-policy",
    meta: privacy_45policyNJjQ8_fzfxkPT_455Edg4zkNyOBfjY4TGyMcq4JPuGlUcMeta || {},
    component: () => import("/usr/src/app/src/pages/privacy/privacy-policy.vue")
  },
  {
    name: "shop-slug",
    path: "/shop/:slug()",
    meta: _91slug_93hB5uh2gHwaRy2EkLDBvpurnI08mwUeCMamauJ_6ZkH8Meta || {},
    component: () => import("/usr/src/app/src/pages/shop/[slug].vue")
  },
  {
    name: "simplycodes-mobile-extension",
    path: "/simplycodes-mobile-extension",
    meta: simplycodes_45mobile_45extensionfauEc0qMpXBLCe1R_45tGy_45_45xhNWA9kBLTXObeJEjEXRcMeta || {},
    component: () => import("/usr/src/app/src/pages/simplycodes-mobile-extension.vue")
  },
  {
    name: "smartshopper",
    path: "/smartshopper",
    meta: smartshopperB2Md0Yz2bQ31mnlKe_bgFXYB5QC6swhwmEILJVGN3nEMeta || {},
    component: () => import("/usr/src/app/src/pages/smartshopper.vue")
  },
  {
    name: "store-merchant",
    path: "/store/:merchant()",
    meta: _91merchant_93aWtXm3rnuN3KJjfjp1TEE6VqXXjR6c7HKgHGnMjW8VIMeta || {},
    component: () => import("/usr/src/app/src/pages/store/[merchant].vue")
  },
  {
    name: "submit-coupon-merchant",
    path: "/submit-coupon/:merchant?",
    meta: _91_91merchant_93_93LBsBme1v1AvMNHFZpwLvEwEz7x8kREIuEXMT5427NhoMeta || {},
    component: () => import("/usr/src/app/src/pages/submit-coupon/[[merchant]].vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsiN_GI1vp6To6fN2pnys3OGyBMtr2lfxUP9XxFL9rZZYMeta || {},
    component: () => import("/usr/src/app/src/pages/terms.vue")
  },
  {
    name: "uninstall",
    path: "/uninstall",
    meta: uninstallu1eXmyXbFT43C7_gHWO4HyKEmeZNMEcoh4V1N4Pz7ZwMeta || {},
    component: () => import("/usr/src/app/src/pages/uninstall.vue")
  },
  {
    name: "verify-code",
    path: "/verify-code",
    meta: verify_45codezhC35tDNkCQzcd0yRg8AzBf6aasbvnAy2KE70DXn57AMeta || {},
    component: () => import("/usr/src/app/src/pages/verify-code.vue")
  }
]