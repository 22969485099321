<template>
  <NuxtLayout>
    <section class="layout-default">
      <div class="text-center py-96">
        <h1 class="mb-16 headline-2 sm:display-1 md:headline-1">
          {{ errorTitle }}
        </h1>
        <p class="body-1">
          {{ errorMessage }}
        </p>
      </div>
    </section>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import * as Sentry from '@sentry/nuxt';

interface ErrorDetails {
  statusCode?: number;
  statusMessage?: string;
  statusTitle?: string;
  message?: string;
}

interface ErrorMessage {
  title: string;
  message: string;
}

const props = defineProps<{ error: ErrorDetails }>();

if (import.meta.server) {
  console.error(props.error);
}

if (props.error) {
  Sentry.captureException(props.error);
}

const ERROR_MESSAGES: Record<number | 'DEFAULT', ErrorMessage> = {
  404: {
    title: 'Page Not Found',
    message: 'The page you\'re looking for was not found.',
  },
  410: {
    title: 'Category Not Found',
    message: 'Category data not found.',
  },
  DEFAULT: {
    title: 'Server Error',
    message: 'An unknown error has occurred, please try again later.',
  },
};

const statusCode = computed(() => Number(props.error?.statusCode || 503));
const errorTitle = computed(() => props.error.statusTitle || ERROR_MESSAGES?.[statusCode.value]?.title || ERROR_MESSAGES.DEFAULT.title);
const errorMessage = computed(() => props.error.statusMessage || ERROR_MESSAGES?.[statusCode.value]?.message || ERROR_MESSAGES.DEFAULT.message);

useHead({
  title: errorTitle.value,
});
</script>
