import type { FeatureFlags } from '@demandio/shared-utils';
import { useAppStore, useUserStore } from '@/stores';

export default defineNuxtRouteMiddleware(async (to) => {
  const appStore = useAppStore();
  const userStore = useUserStore();

  if (to.query?.ff) {
    const { ff } = to.query;
    let newFlags: string[] = [];

    if (typeof ff === 'string') {
      newFlags = ff.includes('|') ? ff.split('|') : [ff];
    } else if (Array.isArray(ff)) {
      newFlags = ff as string[];
    }

    // Combine existing and new flags, removing duplicates
    appStore.featureFlags = [...new Set([...appStore.featureFlags, ...newFlags])];
  }

  if (to.meta?.requiresFeatureFlags) {
    const { requiresFeatureFlags = [] } = to.meta;
    if (!(requiresFeatureFlags as string[]).every((ff: string) => appStore.hasFeatureFlag(ff)))
      return '/404';
  }

  if (to.meta?.requiresUserFeatureFlags) {
    await userStore.fetchLoggedInUser();

    const { requiresUserFeatureFlags = [] } = to.meta;
    if (!(requiresUserFeatureFlags as string[]).every((ff: string) => userStore.hasFeatureFlag(ff as FeatureFlags)))
      return '/404';
  }
});
