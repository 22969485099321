import { useReCaptcha, VueReCaptcha } from 'vue-recaptcha-v3';
// The plugin enables the usage of Google reCAPTCHA in a Nuxt.js application
// by registering the VueReCaptcha plugin with the necessary configuration options.

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const { recaptcha: { v2SiteKey = '' } } = config.public;

  const options = {
    siteKey: v2SiteKey,
    loaderOptions: {
      autoHideBadge: true,
      useRecaptchaNet: true,
      renderParameters: {
        hl: 'en',
      },
    },
  };

  nuxtApp.vueApp.use(VueReCaptcha, options);

  const recaptchaInstance = useReCaptcha();

  return {
    provide: {
      captcha: recaptchaInstance,
    },
  };
});
