import { getPostLogoutRedirectURL, getPostLoginRedirectURL } from '@/util/url';
import * as Sentry from '@sentry/nuxt';

export function useAuth() {
  const route = useRoute();
  const router = useRouter();
  const appStore = useAppStore();
  const userStore = useUserStore();
  const runtimeConfig = useRuntimeConfig();
  const { executeRecaptcha } = useGoogleRecaptcha();
  const { postLoginMessage, EXTENSION_SOURCE } = useExtension();
  const { baseUrl } = runtimeConfig.public;
  const { redirect: appStoreRedirect } = storeToRefs(appStore);

  const { user } = storeToRefs(userStore);
  const profile = computed(() => user.value?.profile);

  const { isMobile } = useDevice();

  enum LoginType {
    GOOGLE = 'google',
    APPLE = 'apple',
    MAGIC_LINK = 'magic_link',
    EMAIL = 'email',
  }

  interface HandleLoginType {
    type: LoginType;
    credential?: string;
    token?: string;
    code?: number;
    email?: string;
  };

  // Calculate source URL for extensions and post extension-install login routes
  const sourceLocation = computed(() => {
    let source = '';

    if (route?.query?.source)
      source = `?source=${route.query.source}`;
    else if (route?.query?.fromExtension)
      source = EXTENSION_SOURCE;

    return source;
  });

  async function handleLogout(overrideRoute?: string) {
    await userStore.logOut();
    const toRoute = overrideRoute || (route.meta?.requireAuth ? '/explore' : route.fullPath);
    window.location.href = getPostLogoutRedirectURL(toRoute, baseUrl);
  }

  async function handleLogin({
    type,
    credential,
    token,
    email,
    code,
  }: HandleLoginType) {
    try {
      if (type === LoginType.GOOGLE) {
        if (!credential) {
          throw new Error('Google credential is required');
        }

        await userStore.loginGoogle(credential);
      } else if (type === LoginType.EMAIL) {
        if (!email) {
          throw new Error('Email is required');
        }

        // eslint-disable-next-line unused-imports/no-unused-vars
        const { token } = await executeRecaptcha('submit');

        // TODO: We should send `token` to the API to verify the re-captcha challenge
        await userStore.loginSendMagicLink(email);

        return; // Don't go to redirect handler
      } else if (type === LoginType.MAGIC_LINK) {
        if (!email || !code) {
          throw new Error('Email and Code are required');
        }

        await userStore.loginWithMagicLink({ email, code });
      } else if (type === LoginType.APPLE) {
        if (!token) {
          throw new Error('Apple token is required');
        }

        await userStore.loginApple(token);
      }

      afterLoginCallback();
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      throw error;
    }
  }

  function handlePostLoginRedirect() {
    if (!profile.value?.postLoginShown) {
      return router.push(`/notify-consent${sourceLocation.value}`);
    }

    let redirectTarget = appStoreRedirect.value || route?.query?.redirect as string || '';

    // "Login" pages
    const loginPages = ['/login', '/verify-code', '/installed/', '/extension/login/'];

    // Redirect to /explore from login pages if there is no redirect target
    if (!redirectTarget && loginPages.some(page => route.fullPath.startsWith(page))) {
      redirectTarget = '/explore';
    }

    // Custom redirect to the installed URL if coming from the post install login
    const targetUrl = getPostLoginRedirectURL(redirectTarget, baseUrl);

    const loginForExtension = !isMobile && ((import.meta.client && window.opener) || route.query?.fromExtension || route.query?.source === 'extension');

    // Clear from the store
    appStore.onRedirect('');

    // Redirect
    if (targetUrl && !loginForExtension) {
      navigateTo(targetUrl, { external: true });
    }
  }

  async function afterLoginCallback() {
    // Try to log the extension in
    postLoginMessage();

    // Handle login redirect
    handlePostLoginRedirect();
  }

  return {
    handleLogout,
    handleLogin,
    LoginType,
  };
}
