import vue3GoogleLogin from 'vue3-google-login';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  nuxtApp.vueApp.use(vue3GoogleLogin, {
    clientId: config.public.googleAuthClientId,
    buttonConfig: {
      type: 'standard',
      theme: 'outline',
      size: 'large',
      text: 'continue_with',
      shape: 'pill',
      width: '320',
      logo_alignment: 'center',
    },
  });
});
