import * as Sentry from '@sentry/nuxt';
import { useAppStore, useUserStore } from '@/stores';

/**
 * Before redirecting to /login, check where we wanted to go so we can go back after log in
 *
 * - Store the "to" value to redirect back to when we log in if we did not come from from a Login page
 * - Store the `redirect` query string if available and we're on the login page
 * - If there is a referrer and we're in a login page, store the referrer
 *
 * Note: This is only executed when redirecting to `/login`, since that's the only case where we want
 * to redirect a user back to where they where.
 */
async function handleRedirectLogic(to: any) {
  const headers = useRequestHeaders();
  const appStore = useAppStore();

  const { referer } = headers;

  let redirect = '';
  if (to?.fullPath && !to?.fullPath?.startsWith('/login') && !to?.fullPath?.startsWith('/notify-consent')) {
    redirect = to.fullPath;
  } else if (to.query.redirect && typeof to.query.redirect === 'string') {
    redirect = to.query.redirect;
  } else if (referer) {
    const referringURL = new URL(referer);
    // eslint-disable-next-line regexp/no-unused-capturing-group
    if (referringURL && /^simplycodes(?:-(dev|local))?.com$/.test(referringURL.hostname))
      redirect = `${referringURL.pathname}${referringURL.search}${referringURL.hash}`;
  }
  await appStore.onRedirect(redirect);
}

export default defineNuxtRouteMiddleware(async (to) => {
  const userStore = useUserStore();
  const { sendGAPageView } = useAnalytics();

  const requireAuth = to.matched.some(record => record.meta?.requireAuth);
  const disallowAuth = to.matched.some(record => record.meta?.disallowAuth);

  try {
    const { isLoggedIn } = useUser();

    // const userData = await userStore.fetchLoggedInUser();
    // Note: User data was previously passed into setGAProperties but is no longer available with GTM, we might want to add something similar back in the future
    await userStore.fetchLoggedInUser();

    // GA pageview evet to load only in the CLIENT
    if (import.meta.client)
      sendGAPageView();

    // Page requires authentication
    if (!isLoggedIn.value && requireAuth) {
      // Handle redirect-back logic
      await handleRedirectLogic(to);

      // Send user to log in
      return navigateTo({ path: '/login', query: { redirect: to.fullPath } });
    } else if (isLoggedIn.value && disallowAuth && !to.path.includes('verify-code')) {
      // Page requires a logged-out user
      return navigateTo('/my-account');
    } else {
      return true;
    }
  } catch (error) {
    Sentry.captureException(error);

    throw createError({ statusCode: 503 });
  }
});
