import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores';

export default function useUser() {
  const userStore = useUserStore();

  const { user } = storeToRefs(userStore);

  const isLoggedIn = computed(() => user?.value?.isGuest === false || false);

  return {
    isLoggedIn,
  };
}
