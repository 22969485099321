import UAParser from 'ua-parser-js';
import { isValidBrowser } from './browser-extension';

function _getParedUserAgent() {
  const headers = useRequestHeaders();
  const ua = import.meta.client ? navigator.userAgent : headers['user-agent'];
  return new UAParser(ua);
}
// TODO: Get rid of this in favor of '@nuxtjs/device'? Test how caching handles this.
export function getBrowser() {
  const uaResults = _getParedUserAgent();
  const browser = uaResults.getBrowser();

  return browser?.name || 'Chrome';
}

export function getOs() {
  const uaResults = _getParedUserAgent();
  const os = uaResults.getOS();

  return os?.name;
}

export function parseUserAgent() {
  const uaResults = _getParedUserAgent();

  return uaResults.getResult();
}

interface ButtonTextOptions {
  ios: string;
  long: string;
  short: string;
  fallback: string;
  mobile: string;
}

const defaultButtonTextOptions: ButtonTextOptions = {
  ios: 'Add to Safari ~ it\'s free',
  long: 'Add SimplyCodes to [browser]',
  short: '+ Add to [browser]',
  fallback: 'Get it now',
  mobile: 'Get the app',
};

export function getButtonText(longForm = false, options?: Partial<ButtonTextOptions>, isMobile?: boolean): string {
  const browser = getBrowser();
  const os = getOs();
  const { ios, long, short, fallback, mobile } = { ...defaultButtonTextOptions, ...options };

  if (!browser || !os)
    return fallback;

  if (isMobile)
    return (mobile as string).replace('[os]', os);

  // On iOS, always display `Get the app`
  if (os === 'iOS') {
    const iosBrowser = browser === 'Mobile Safari' || !isValidBrowser(browser) ? 'Safari' : browser;
    // Facebook fix
    return (ios as string).replace('[browser]', iosBrowser);
  }

  if (!isValidBrowser(browser))
    return fallback;

  if (longForm)
    return long.replace('[browser]', browser);

  return short.replace('[browser]', browser);
}
