import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/usr/src/app/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_1PKapGjTEMgLucTQzkj47OtZs396aw7yCLlwZI_zd2w from "/usr/src/app/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/usr/src/app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk from "/usr/src/app/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import http_KJt2_BwXQ8QKbqIiHb6pD5Wza2DA_3QCjZdUjHSbo6o from "/usr/src/app/node_modules/.c12/gh_demandio_nuxt_VdUHuLyAlA/src/plugins/http.ts";
import extension_channel_client_n6po92Eyjz21Gf_yeGtdrb2PgzdH_WznvMoseuRaHYE from "/usr/src/app/src/plugins/extension-channel.client.ts";
import google_login_client_zecKJstt_Spaj0eEMEdQ20hK8kU2ugeJnn2fJ4odZrE from "/usr/src/app/src/plugins/google-login.client.ts";
import recaptcha_client_IYYyWtVSIeCzzmmWslMCQ_dezV7AB6zG5A7ql5NJF70 from "/usr/src/app/src/plugins/recaptcha.client.ts";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  view_transitions_client_1PKapGjTEMgLucTQzkj47OtZs396aw7yCLlwZI_zd2w,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk,
  http_KJt2_BwXQ8QKbqIiHb6pD5Wza2DA_3QCjZdUjHSbo6o,
  extension_channel_client_n6po92Eyjz21Gf_yeGtdrb2PgzdH_WznvMoseuRaHYE,
  google_login_client_zecKJstt_Spaj0eEMEdQ20hK8kU2ugeJnn2fJ4odZrE,
  recaptcha_client_IYYyWtVSIeCzzmmWslMCQ_dezV7AB6zG5A7ql5NJF70
]