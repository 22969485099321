const redirectMap = {
  '/open-app': '/',
  '/best-coupon-app-extension': '/blog/best-coupon-app-extension',
} as const;

type RedirectPaths = keyof typeof redirectMap;

/**
 * Middleware function that redirects certain URLs to specified destinations.
 *
 * @param {object} to - The `to` object from the Nuxt.js route middleware.
 * @returns {undefined|Promise} - If the current URL is in the `redirectMap` object, the function returns a Promise that resolves to `undefined`. Otherwise, the function returns `undefined`.
 */
export default defineNuxtRouteMiddleware((to) => {
  const path = to.path as RedirectPaths;

  if (isRedirectPath(path)) {
    return navigateTo(redirectMap[path], { redirectCode: 301 });
  }
});

/**
 * Type guard to check if a given path is a key in the redirectMap
 *
 * @param path - The path to check
 * @returns True if the path is a key in redirectMap, false otherwise
 */
function isRedirectPath(path: string): path is RedirectPaths {
  return path in redirectMap;
}
