import { defineStore } from 'pinia';
import isEqual from 'lodash-es/isEqual';
import uniqBy from 'lodash-es/uniqBy';
import uniqWith from 'lodash-es/uniqWith';
import type {
  Activity,
  ActivityFeedResponse,
  BagTypesResponseDto,
  ConversionRate,
  Mission,
  RevealGrabBagResponseDto,
  RewardCashoutDTO,
  UserKarma,
  UserPaypalData,
  UserReward,
} from '@demandio/shared-utils';
import { ActivityFilters, UserRewardStatus } from '@demandio/shared-utils';
import * as Sentry from '@sentry/nuxt';
import { DEFAULT_PAGE_SIZE } from '@/util/pagination';

const TOKENS_TO_CONVERT_FALLBACK_NUM = 50;

export const useKarmaStore = defineStore('karma', () => {
  const context = useNuxtApp();

  const activities = shallowRef(<Activity[]>[]);
  const userFeed = shallowRef(<ActivityFeedResponse>{
    meta: {
      itemCount: 0,
      totalItems: 0,
      itemsPerPage: 0,
      totalPages: 0,
      currentPage: 0,
    },
    links: {
      first: '',
      previous: '',
      next: '',
      last: '',
    },
    items: [],
  });

  const karma = shallowRef<UserKarma>();
  const userMissions = shallowRef<Mission[]>([]);
  const userRewards = shallowRef<UserReward[]>([]);
  const prizeBagTypes = shallowRef<BagTypesResponseDto[]>([]);

  const tokensToConvert = shallowRef(TOKENS_TO_CONVERT_FALLBACK_NUM);

  const nextRewardInKarma = computed(() => (karma.value && karma.value.nextKarmaMilestone) || 0);

  const nextMoneyReward = computed(() => (karma.value && karma.value.nextRewardValue) || 0);

  const nextRewardProgress = computed(() => {
    if (!karma.value?.karmaToNextReward)
      return 0;

    const { karmaToNextReward } = karma.value;

    const currentRewardKarma = nextRewardInKarma.value - karmaToNextReward;

    return (currentRewardKarma * 100) / nextRewardInKarma.value;
  });

  let activityListPromise: Promise<unknown> | undefined;
  let conversionRatePromise: Promise<unknown> | undefined;

  const activityList = shallowRef<Activity[]>([]);
  const conversionRate = ref(0);
  const userPaypalData = shallowRef<UserPaypalData>();

  const setUserRewards = (rewards: UserReward[]) => {
    userRewards.value = rewards;
  };

  const fetchUserRewards = () => {
    return context.$http
      .get(`/karma/rewards?${new URLSearchParams({ status: UserRewardStatus.ACCEPTED })}`)
      .then((response: any) => setUserRewards(response.data));
  };

  const fetchUserKarmaData = async (forceRefetch = false) => {
    // Return already-fetched data
    if (karma.value?.userId && !forceRefetch)
      return karma.value;

    try {
      karma.value = (await context.$http.get('karma/stats/self', {}, context.$http.API_VERSIONS.v1)) as UserKarma;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const fetchActivities = async (page?: number, limit?: number): Promise<Activity[] | void> => {
    try {
      const response = (await context.$http.get(
        'karma/activities/feed',
        { page: page || 1, limit: limit || DEFAULT_PAGE_SIZE },
        context.$http.API_VERSIONS.v1,
      )) as Activity[];

      activities.value = uniqBy([...activities.value, ...response], 'id');

      return activities.value;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const fetchUserMissions = async (): Promise<Mission[] | void> => {
    try {
      const response = (await context.$http.get('karma/missions/self', {}, context.$http.API_VERSIONS.v1)) as Mission[];

      // Only show the Referral and First purchase missions.
      const filteredResponse = response.filter(item => ['first-purchase', 'referral'].includes(item.type));

      userMissions.value = filteredResponse;

      return userMissions.value;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const fetchActivityList = async (): Promise<Activity[] | void> => {
    try {
      if (!activityListPromise)
        activityListPromise = context.$http.get('karma/activities', {}, context.$http.API_VERSIONS.v1);

      const response = (await activityListPromise) as Activity[];
      activityList.value = response;
      activityListPromise = undefined;

      return activityList.value;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const fetchConversionRate = async (): Promise<number | void> => {
    try {
      if (!conversionRatePromise)
        conversionRatePromise = context.$http.get('karma/stats/conversion-rate', {}, context.$http.API_VERSIONS.v1);

      const response = (await conversionRatePromise) as ConversionRate;
      conversionRate.value = response.conversionRate;
      conversionRatePromise = undefined;

      return conversionRate.value;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const logUserActivity = async (payload: { type: string }) => {
    try {
      await context.$http.post('karma/activities', payload, context.$http.API_VERSIONS.v1);

      fetchActivities();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const fetchPayPalInfo = async () => {
    try {
      const response = (await context.$http.get(
        'karma/users/self/paypal',
        {},
        context.$http.API_VERSIONS.v1,
      )) as UserPaypalData;
      if (response)
        userPaypalData.value = response;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  /**
   * Fetches the list of bag types
   *
   * @returns {BagTypesResponseDto[]} - The list of bag types
   */
  const fetchBagTypes = async (): Promise<BagTypesResponseDto[] | void> => {
    try {
      const response = (await context.$http.get(
        'karma/prize-bags',
        { alternate: true },
        context.$http.API_VERSIONS.v1,
      )) as BagTypesResponseDto[];

      prizeBagTypes.value = response;
      tokensToConvert.value = prizeBagTypes.value?.[0]?.tokensRequired || TOKENS_TO_CONVERT_FALLBACK_NUM;

      return response;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  /**
   * Opens and reveals a grab bag
   *
   * @param tokensSpent number
   * @returns {Promise<RevealGrabBagResponseDto>} The revealed grab bag details
   */
  const redeemAndRevealBag = (tokensSpent: number): Promise<RevealGrabBagResponseDto> => {
    return context.$http.post(
      'karma/prize-bags/redeem-and-reveal?alternate=true',
      { tokensSpent },
      context.$http.API_VERSIONS.v1,
    ) as Promise<RevealGrabBagResponseDto>;
  };

  /**
   * Returns a list of the new activities, cash outs, redemptions, etc
   *
   * @param filter - The filter to apply
   * @param page - The page we're requesting
   * @returns {ActivityFeedResponse} - The list of activities
   */
  const fetchUserHistory = async (
    filter: ActivityFilters = ActivityFilters.ALL,
    page = 1,
    limit = DEFAULT_PAGE_SIZE,
  ): Promise<ActivityFeedResponse | void> => {
    try {
      const response = (await context.$http.get(
        '/karma/user-feed/history',
        { type: filter, page, limit, alternate: true },
        context.$http.API_VERSIONS.v1,
      )) as ActivityFeedResponse;

      userFeed.value = {
        meta: response.meta,
        links: response.links,
        items: page === 1 ? response.items : uniqWith([...userFeed.value.items, ...response.items], isEqual),
      };

      return response;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  /**
   * Sends a Karma claim request
   *
   * @param payload - The payload to send
   *
   */
  const karmaClaim = (payload: FormData) => {
    context.$http.post('/karma/claims/karma', payload, context.$http.API_VERSIONS.v1, {});
  };

  const requestTokenPayment = (payload: RewardCashoutDTO) => {
    const { amount, paymentType } = payload;

    return context.$http
      .post(`/karma/rewards/redeem/payments`, {
        amount,
        paymentType,
      })
      .then(() => {
        fetchUserRewards();
        fetchUserKarmaData(true);
      });
  };

  const requestPrizePayment = (payload: RewardCashoutDTO) => {
    const { amount, paymentType } = payload;

    return context.$http
      .post(`/karma/prizes/cashout`, {
        amount,
        paymentType,
      })
      .then(() => {
        fetchUserRewards();
        fetchUserKarmaData(true);
      });
  };

  return {
    activities,
    activityList,
    conversionRate,
    fetchActivities,
    fetchActivityList,
    fetchBagTypes,
    fetchConversionRate,
    fetchPayPalInfo,
    fetchUserHistory,
    fetchUserKarmaData,
    fetchUserMissions,
    karma,
    karmaClaim,
    logUserActivity,
    nextMoneyReward,
    nextRewardInKarma,
    nextRewardProgress,
    prizeBagTypes,
    userFeed,
    userMissions,
    userPaypalData,
    tokensToConvert,
    requestPrizePayment,
    requestTokenPayment,
    redeemAndRevealBag,
  };
});
