import { useMutationObserver } from '@vueuse/core';

const EXTENSION_MESSAGES = {
  LOGIN: 'SimplyCodesLogin',
};

const EXTENSION_SOURCE = '?source=extension';

export default function useExtension() {
  const extensionIsInstalled = ref(false);
  const route = useRoute();
  const context = useNuxtApp();

  const { isMobile } = useDevice();

  // Log the extension in
  const postLoginMessage = () => {
    context.$broadcast.post({ type: EXTENSION_MESSAGES.LOGIN });

    // If this is a popup to log in the extension, close it
    // Only for the desktop extension
    if (!isMobile && (window.opener || route.query?.fromExtension || route.query?.source === 'extension')) {
      // Wait until message is broadcasted before closing.
      // Swallow errors
      setTimeout(() => {
        try {
          window.close();
        } catch {}
      }, 250);
    }
  };

  if (import.meta.client) {
    onMounted(() => {
      // ToDo: even do we have the `client` check it's still getting the `onMounted is called when there is no active component instance to be associated with. Lifecycle injection APIs can only be used during execution of setup(). If you are using async setup(), make sure to register lifecycle hooks before the first await statement.` warning
    // Listen for body mutations.
    // If after a mutation the extension container is found, update the ref and stop the observer.
      const { stop } = useMutationObserver(
        document.body,
        () => {
          if (document.querySelector('[id^="SCExtension"]')) {
            extensionIsInstalled.value = true;
            stop();
          }
        },
        { childList: true, subtree: true },
      );
    });
  }

  return {
    extensionIsInstalled,
    postLoginMessage,
    EXTENSION_SOURCE,
  };
}
