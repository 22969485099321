import { defineStore } from 'pinia';
import type { FeatureFlags, LegacyUserResponse, User, UserProfile } from '@demandio/shared-utils';
import * as Sentry from '@sentry/nuxt';
import { useKarmaStore } from './KarmaStore';
import { AUTH_CLIENT_ID, AUTH_GUEST_SCOPES, AUTH_USER_SCOPES, WINDOW_MESSAGE_EVENT_TYPES } from '@/util/constants';

// TODO: Revisit this
function standardErrorHandler(callback: (message: string) => void) {
  return (error: any) => {
    Sentry.captureException(error);
    const { data: { message = 'Access denied' } = {} } = error.response || {};
    callback(message);
    throw new Error(error.message);
  };
}

export const useUserStore = defineStore('user', () => {
  const context = useNuxtApp();
  const karmaStore = useKarmaStore(context.$pinia);

  const user = shallowRef<User>();
  const profile = shallowRef<UserProfile>();

  const isUserLoading = ref(false);
  const lastEmail = ref('');
  const rememberMe = ref(false);
  const acceptsMailingList = ref(true);
  const isEditor = ref(false);
  const userScopes = ref<string[]>([]);
  const loginError = ref(false);
  const verifyingIdentity = ref(false);

  async function updateProfile(fieldsToUpdate: Record<string, any>) {
    return await context.$http.put('/self', fieldsToUpdate);
  }

  const hasFeatureFlag = (flag: FeatureFlags) => {
    return user.value?.featureFlags?.includes(flag) || false;
  };

  const clearUser = () => {
    user.value = undefined;
    userScopes.value = [];
    isEditor.value = false;
    isUserLoading.value = false;
    loginError.value = false;
  };

  const setUserProfile = (userProfile: UserProfile) => {
    profile.value = userProfile;
    userScopes.value = [];
    isUserLoading.value = false;
    loginError.value = false;
  };

  const setRememberMe = (value: boolean) => {
    rememberMe.value = value;
  };

  const setLastEmail = (value: string) => {
    lastEmail.value = value;
  };

  /**
   * Fetches the logged-in user's data and updates the store
   * @param {string} loginMethod - The method used for login (optional)
   * @returns {Promise<User|undefined>} The fetched user data or undefined if no user is logged in
   */
  async function fetchLoggedInUser(loginMethod = '') {
    isUserLoading.value = true;

    const fetched = (await context.$http.get('user', {}).catch(() => {
      clearUser();
    })) as LegacyUserResponse;

    const { users: [fetchedUserData] = [], scopes = [AUTH_USER_SCOPES[0]] } = fetched || {};

    // No user, or the user has no loginMethod (ie. a Guest user), we're not "logged in"
    if (!fetchedUserData || !fetchedUserData.profile?.loginMethods?.length) {
      clearUser();
    } else {
      isEditor.value = fetchedUserData?.editor || false;
      profile.value = fetchedUserData.profile;
      userScopes.value = scopes;
      user.value = fetchedUserData;

      // Fetch user karma after logging in (fire and forget)
      karmaStore.fetchUserKarmaData();

      if (loginMethod) {
        // Use the `postLoginShown` flag to determine if this is a Signup or Login
        // If it was shown, the user is logging in
        const isLogin = fetchedUserData.profile.postLoginShown;
        const trackName = isLogin ? 'Login Success' : 'Signup Success';

        const { buildAnalyticsData, sendGAEvent } = useAnalytics();

        const trackingData = buildAnalyticsData({
          category: 'Login',
          page: 'Login',
          sso_type: loginMethod,
        });

        try {
          sendGAEvent(trackName, { ...trackingData });
        } catch (error) {
          console.error(`Could not track "${trackName}" Event`, error);
          Sentry.captureException(error);
        }
      }

      return fetchedUserData;
    }
  }

  interface LoginCredentials {
    grant_type: string;
    token: string | number;
    email?: string;
  }
  async function _genericLogin(credentials: LoginCredentials, fetchUserType?: string) {
    isUserLoading.value = true;

    const { grant_type, token, email } = credentials;

    const payload = {
      client_id: AUTH_CLIENT_ID,
      rememberMe: rememberMe.value,
      scopes: fetchUserType ? AUTH_USER_SCOPES : AUTH_GUEST_SCOPES,
      forceLogin: true,
      mailingList: acceptsMailingList.value,
      [grant_type]: token,
      grant_type,
    };

    if (email)
      payload.email = email;

    try {
      const response = await context.$http.post('/auth/login', payload);
      if (fetchUserType) {
        await fetchLoggedInUser(fetchUserType);

        context.$broadcast.post({ type: WINDOW_MESSAGE_EVENT_TYPES.LOGIN });
      }

      return response;
    } catch (error) {
      standardErrorHandler((value: any) => (loginError.value = value));
      throw error;
    } finally {
      isUserLoading.value = false;
    }
  }

  async function loginSendMagicLink(email: string) {
    return await _genericLogin({ grant_type: 'email', token: email });
  }

  async function loginWithMagicLink(request: { email: string; code: number }) {
    return await _genericLogin({ grant_type: 'magic_link', email: request.email, token: request.code }, 'email');
  }

  async function loginGoogle(token: string) {
    return await _genericLogin({ grant_type: 'google_id_token', token }, 'google');
  }

  async function loginApple(token: string) {
    return await _genericLogin({ grant_type: 'apple_access_code', token }, 'apple');
  }

  async function logOut() {
    isUserLoading.value = true;
    try {
      const response = await context.$http.post('/auth/logout');
      return response;
    } finally {
      isUserLoading.value = false;
      clearUser();

      context.$broadcast.post({ type: WINDOW_MESSAGE_EVENT_TYPES.LOGOUT });
    }
  }

  const uploadAvatar = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file, file.name);

    try {
      return await context.$http.put('/self', formData, context.$http.API_VERSIONS.v1);
    } catch {
      standardErrorHandler((value: any) => (loginError.value = value));
    }
  };

  const updateRememberMe = async (shouldRemember: boolean) => {
    try {
      const response = await context.$http.post('/auth/login', {
        grant_type: 'refresh_token',
        rememberMe: shouldRemember,
        client_id: AUTH_CLIENT_ID,
      });
      rememberMe.value = shouldRemember;
      return response;
    } catch {
      standardErrorHandler((value: any) => (loginError.value = value));
    }
  };

  const analyticsOptIn = async (isEnabled: boolean) => {
    try {
      return await context.$http.put('/analytics-opt-in', {
        analyticsOptIn: isEnabled,
      });
    } catch {
      standardErrorHandler((value: any) => (loginError.value = value));
    }
  };

  // Plaid Identity Verification
  const setUserPlaidStatus = (plaid: any) => {
    if (profile.value) {
      profile.value.plaidStatus = plaid.status;
      profile.value.isVerified = plaid.isVerified;
    }
  };

  const setVerifyingIdentity = (value: boolean) => {
    verifyingIdentity.value = value;
  };

  const getIdentityVerificationRequest = async () => {
    setVerifyingIdentity(true);

    try {
      const response = await context.$http.get('/plaid/identity-verification');
      setUserPlaidStatus(response);
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error while retrieving identity verification request', error);
      setVerifyingIdentity(false);
    }

    return null;
  };

  const createIdentityVerificationRequest = async (retry = false) => {
    setVerifyingIdentity(true);

    try {
      const response: any = await context.$http.post(`/plaid/identity-verification${retry ? '/retry' : ''}`);
      // TODO: Refactor this to use a mutation with DTO instead
      return response;
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error while creating identity verification request', error);
      setVerifyingIdentity(false);
    }

    return null;
  };

  return {
    clearUser,
    fetchLoggedInUser,
    hasFeatureFlag,
    acceptsMailingList,
    lastEmail,
    logOut,
    loginApple,
    loginError,
    loginGoogle,
    loginSendMagicLink,
    loginWithMagicLink,
    profile,
    rememberMe,
    setLastEmail,
    setRememberMe,
    setUserProfile,
    updateProfile,
    isEditor,
    user,
    uploadAvatar,
    updateRememberMe,
    analyticsOptIn,
    // Payment Verification
    verifyingIdentity,
    setVerifyingIdentity,
    getIdentityVerificationRequest,
    createIdentityVerificationRequest,
  };
});
