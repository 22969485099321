import type { IReCaptchaComposition } from 'vue-recaptcha-v3';
/**
 * The exported executeRecaptcha function allows
 * you to execute reCAPTCHA actions
 * and retrieve the reCAPTCHA token along with the header options
 * to be used in subsequent requests.
 */
export function useGoogleRecaptcha() {
  async function executeRecaptcha(action: string) {
    if (!action)
      return;

    const { recaptchaLoaded, executeRecaptcha } = useNuxtApp().$captcha as IReCaptchaComposition;

    /**
     * Wait for the recaptchaInstance to be loaded
     * by calling the recaptchaLoaded method.
     * This ensures that the reCAPTCHA library is fully loaded
     * and ready to execute reCAPTCHA actions.
     */
    await recaptchaLoaded();

    const token = await executeRecaptcha(action);

    const headerOptions = {
      headers: {
        'google-recaptcha-token': token,
      },
    };

    return { token, headerOptions };
  }

  return { executeRecaptcha };
}
